import { css } from '@emotion/react';
import { color, spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { ColorInput } from 'modules/app/components/shared/ImageGridLayout';
import { wrappers } from 'style/variables';
import { link, details } from 'modules/landing/styles/Header.styles';

export const wrapper = css`
  max-width: ${wrappers.page};
  margin: 0 auto;
  padding: 0 var(--spacing-xlrg);
  box-sizing: content-box;
`;

export const grid = css`
  display: grid;
  row-gap: var(--spacing-huge);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);
  }
`;

export const doubleMediaLayout = (bgColor?: ColorInput) => css`
  padding-top: var(--spacing-xlrg);
  padding-bottom: var(--spacing-huge);

  background-color: ${bgColor ? color(...bgColor) : 'transparent'};

  @media ${breakpoints.medium} {
    padding-top: calc(var(--unit) * 25);
    padding-bottom: calc(var(--unit) * 25);
  }
`;

export const infoSection = css`
  display: grid;
  row-gap: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(6, 1fr);
    column-gap: var(--spacing-xxxlrg);

    grid-column-end: span 6;
    align-content: space-between;
  }
`;

export const infoSectionCta = css`
  ${infoSection};
  row-gap: ${spacing(36)};

  @media ${breakpoints.medium} {
    column-gap: ${spacing(56)};
  }
`;

export const heading = (headingColor: ColorInput = ['text', 1]) => css`
  color: ${color(...headingColor)};

  @media ${breakpoints.medium} {
    grid-column-end: span 5;
  }
`;

export const description = (descColor: ColorInput = ['text', 1]) => css`
  color: ${color(...descColor)};

  @media ${breakpoints.medium} {
    grid-column-end: span 4;
  }
`;

export const media = css`
  position: relative;
  margin: 0;
  margin-right: -1.25rem;

  grid-row: 1;

  overflow-x: hidden;

  @media ${breakpoints.medium} {
    overflow-x: unset;

    grid-column-end: span 6;
  }
`;

export const mediaLeft = css`
  ${grid};

  @media ${breakpoints.medium} {
    section {
      grid-column-start: 7;

      h2 {
        grid-column-start: 2;
      }

      p {
        grid-column-start: 2;
      }

      > div {
        grid-column-start: 2;
      }
    }

    figure {
      grid-column-start: 1;
    }
  }

  @media ${breakpoints.smallMax} {
    figure {
      img {
        min-width: 440px;
      }
    }
  }
`;

export const mediaRight = css`
  ${grid};

  @media ${breakpoints.medium} {
    section {
      grid-column-start: 1;
    }

    figure {
      grid-column-start: 8;
    }
  }

  @media ${breakpoints.smallMax} {
    figure {
      img {
        min-width: 500px;
      }
    }
  }
`;

export const mediaHero = css`
  ${grid};

  @media ${breakpoints.medium} {
    row-gap: calc(var(--unit) * 25);

    section {
      grid-column: 1 / -1;

      grid-template-columns: repeat(12, 1fr);

      h2 {
        grid-column-start: 1;
      }

      p {
        grid-column-start: 8;
      }
    }

    figure {
      margin: 0;
      width: 100%;
      height: auto;

      grid-row: 1;
      grid-column: 1 / -1;

      justify-self: center;
    }
  }

  @media ${breakpoints.smallMax} {
    figure {
      overflow-x: hidden;

      img {
        min-width: 720px;
      }
    }
  }
`;

export const gridConfig = {
  mediaLeft: mediaLeft,
  mediaRight: mediaRight,
  mediaHero: mediaHero,
};

export type GridConfig = keyof typeof gridConfig;

export const cta = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  @media ${breakpoints.medium} {
    grid-column-end: span 5;
  }
`;

export const ctaBtn = css`
  ${link};
`;

export const ctaText = css`
  ${details};
`;
